body {
  padding: 0;
}

#root {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

#banderas-container {
  display: flex;
  position: absolute;
  top: 55px;
  right: 0;
  cursor: pointer;
  #spanish,
  #english {
    width: 30px;
    height: 50px;
    margin-right: 5px;
    &:hover {
      transform: scale(1.1);
    }
  }
  @media screen and (min-width: 320px) and (max-width: 369px) {
    top: 70px;
    #spanish,
    #english {
      width: 25px;
      height: 30px;
    }
  }
}

.imgContainer {
  .photo {
    width: 35%;
    border: 3px darkgrey solid;
  }
}
.hero {
  font-family: "Fira Sans", sans-serif;
  animation-name: backInDown;
  animation-duration: 1s;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 320px) and (max-width: 425px) {
    margin-top: 0;
  }
}

#sobre-mi-container {
  font-family: "Fira Sans", sans-serif;
}

.sobreMi {
  animation-name: slideInDown;
  box-shadow: 0px 0px 10px grey;
  animation-duration: 1s;
  width: 100vw;
  max-width: 70%;
  margin: 0 auto 0 auto;
  @media screen and (min-width: 320px) and (max-width: 425px) {
    max-width: 90%;
    margin-top: 75px;
  }
}
.habilidades {
  box-shadow: 0px 0px 10px grey;
  animation-name: slideInDown;
  animation-duration: 1s;
  width: 100vw;
  max-width: 70%;
  margin: 6% auto;
  #bootstrap {
    padding-top: 10px;
    max-width: 75px;
    max-height: 84px;
  }
  .sass {
    padding-top: 14px;
    max-width: 89px;
    max-height: 100px;
  }
  .react {
    padding-top: 10px;
    max-width: 85px;
    max-height: 100px;
    margin-right: 20px;
  }
  .python {
    margin-top: 28px;
    max-width: 74px;
    max-height: 100px;
  }
  .cpp {
    margin-top: 25px;
    max-width: 73px;
    max-height: 100px;
  }
  .git {
    margin-top: 25px;
    max-width: 79px;
    max-height: 100px;
  }
  @media screen and (min-width: 320px) and (max-width: 425px) {
    max-width: 90%;
    .html {
      max-width: 75px;
      max-height: 100px;
    }
    .css {
      margin-top: 35px;
      max-width: 75px;
      max-height: 100px;
    }
    .javaScript {
      margin-top: 35px;

      max-width: 75px;
      max-height: 100px;
    }
    #bootstrap {
      max-width: 70px;
      max-height: 90px;
    }
    .sass {
      max-width: 85px;
      max-height: 100px;
    }
    .react {
      max-width: 90px;
      max-height: 100px;
      margin: 30px 0 0 0;
    }
    .python {
      margin-top: 35px;
      max-width: 80px;
      max-height: 100px;
    }
    .cpp {
      margin-top: 35px;
      max-width: 75px;
      max-height: 100px;
    }
    .git {
      margin-top: 35px;
      max-width: 80px;
      max-height: 100px;
    }
  }
}
.habilidad {
  @media screen and (min-width: 320px) and (max-width: 425px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 30px;
  }
}
.habilidades .habilidad {
  max-height: 90px;
}

#portafolio {
  margin: 8% 20px;
  max-height: 100vh;

  .card {
    width: 14rem;
  }
}

#portafolio-container {
  animation-name: fadeIn;
  animation-duration: 2s;
  font-family: "Fira Sans", sans-serif;
  width: 100vw;
  max-width: 75%;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0 auto 0 auto;
  .project {
    margin: 15px;
    top: 5%;
    color: #393e46;
    box-shadow: 5px 3px 10px darkgrey;
    border-radius: 10px;
    overflow: hidden;

    &:hover {
      animation-name: pulse;
      animation-duration: 0.8s;
      border: 1px black solid;

      .project-name {
        font-weight: bold;
      }
    }
    .img-project {
      width: auto;
      background-repeat: no-repeat;
      background-size: cover;
      backface-visibility: visible;
    }
  }
}

#contacto-container {
  font-family: "Fira Sans", sans-serif;
  animation-name: fadeIn;
  animation-duration: 2s;
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  h2 {
    margin-top: 5%;
    @media screen and (min-width: 320px) and (max-width: 369px) {
      margin-top: 10%;
    }
  }
  .crear-img {
    margin-top: 30px;
    border: 2px solid darkgrey;
    border-radius: 20px;
    max-width: 300px;
    max-height: 250px;
  }
  #redes {
    @media screen and (min-width: 320px) and (max-width: 369px) {
      margin-top: 10%;
    }
    @media screen and (min-width: 370px) and (max-width: 500px) {
      margin-top: 30%;
    }
    margin-top: 4%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 1.5%;
    border-radius: 50%;
    width: 100%;
    max-width: 50vw;

    .red {
      @media screen and (min-width: 320px) and (max-width: 500px) {
        width: 40px;
        margin: 0 10px;
      }
      width: 50px;
      border-radius: 50%;
      &:hover {
        animation-name: tada;
        animation-duration: 1s;
      }
    }
  }
}

.footer {
  min-width: 100%;
}
